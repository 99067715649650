<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <div class="essay">
      <div class="essayTitle">{{ list.Title }}</div>
      <div class="rikind">{{ list.RIKindDesc }}</div>
      <div v-if="list.ThematicUrl"
        class="essayImg">
        <img :src="list.ThematicUrl"
          alt="" />
      </div>
      <p class="essayContent"
        v-html="list.Content"></p>
      <div class="video"
        v-if="list.Video">
        <video style="width: 100%"
          :src="list.Video"
          controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </div>
    </div>
  </div>
</template>
<script>
import { WeGetRealInfo } from "@/api/index";
export default {
  data () {
    return {
      list: {},
    };
  },
  methods: {
    // 获取资讯详情
    getRealInfo () {
      WeGetRealInfo({ riId: this.$route.params.Id }).then((res) => {
        this.list = res.data.data;
        // this.list.Content = this.list.Content.replace(/<.*?>/ig,"")
        // console.log(this.list.Content);
      });
      // this.activated()
    },

    activated () {
      var _this = this;
      _this.uuid = _this.$route.query.uuid;
      var shareUrl = global.BASE_SHARE_URL + 'grade?uuid=' + _this.uuid;
      this.$wxShare.updateWxShareConfig({
        title: '标题',
        desc: '简介',
        link: shareUrl
      });
    }
  },
  mounted () {
    this.getRealInfo();
  },
};
</script>
<style>
</style>